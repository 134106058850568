<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="dates"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                :value="formattedDates()"
                :label="filter.label"
                prepend-icon="mdi-calendar"
                readonly
                v-on="on"
                clearable
                @click:clear="clearSelectedFilter()"
                hide-details
                class="pb-2"
                style="min-width: 200px;"
            ></v-text-field>
        </template>
        <v-date-picker color="primary"
            v-model="dates"
            no-title
            scrollable
            range
        >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="menu = false"
            >
                Cancelar
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.menu.save(dates), setDatepickerValue()"
            >
                Confirmar
            </v-btn>
        </v-date-picker>
    </v-menu>
</template>

<script>
import moment from 'moment'

export default {
    props: {
        filter: { type: Object, required: true },
        multiple: { type: Boolean, required: true },
        label: { type: String, required: true },
        items: { type: Array, required: true },
        clearAllFilters: { type: Boolean },
        required: { type: Boolean, required: true },
        disabled: { type: Boolean, required: true }
    },

    data () {
        return {
            dates: [],
            menu: false
        }
    },

    methods: {
        formattedDates() {
            let date1, date2, formattedDates

            if (this.dates.length === 0) {
                formattedDates = null
            } else if (this.dates.length === 1) {
                date1 = moment.utc(this.dates[0]).format("DD/MM/YYYY")
                formattedDates = date1
            } else if (this.dates.length === 2) {
                let dates = this.compareDates()

                date1 = moment.utc(dates[0]).format("DD/MM/YYYY")
                date2 = moment.utc(dates[1]).format("DD/MM/YYYY")

                formattedDates = `${date1} - ${date2}`
            }

            return formattedDates
        },

        compareDates() {
            let comparedDates
            
            if (moment.utc(this.dates[0]).diff(moment.utc(this.dates[1])) < 0) {
                comparedDates = [this.dates[0], this.dates[1]]
            } else {
                comparedDates = [this.dates[1], this.dates[0]]
            }

            return comparedDates
        },

        setDatepickerValue() {
            let dates = this.compareDates()

            let datepickerValue = {
                column: this.filter.column,
                value: {
                    value: moment(dates[0]).format("YYYY-MM-DD[T]HH:mm:ss[.000Z]"),
                    value2: moment(dates[1]).format("YYYY-MM-DD[T]HH:mm:ss[.000Z]")
                }
            }

            this.$emit('setDatepickerValue', datepickerValue)
        },

        clearSelectedFilter() {
            this.$emit('filtersCounter', -1)
            this.$emit('removeSelection')
            this.dates = []
            this.filters = 0

            this.clearDatepickerValue()  
        },

        clearDatepickerValue() {
            let datepickerValue = {
                column: this.filter.column,
                value: null
            }

            this.$emit('setDatepickerValue', datepickerValue)
        }
    },

    watch: {
        clearAllFilters(val) {
            if (val === true) {
                this.dates = []
                this.filters = 0

                this.$emit('changeClearAllFiltersBackToFalse')
                this.clearDatepickerValue()
            }
        }
    }
}
</script>

<style>

</style>